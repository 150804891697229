// 获取列表
const getListURL = `${API_CONFIG.baseURL}smDeviceAction!deviceRoomList.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}smDeviceAction!exportDeviceRoomToExcel.action`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}device/room/batch`
// 分类列表
const getCateGoryURL = `${API_CONFIG.butlerBaseURL}sysCodeword/geSysCodeWord?typeValue=DEVICE_ROOM_CATEGORY`
// 新增
const saveDeviceRoomURL = `${API_CONFIG.baseURL}smDeviceAction!saveDeviceRoom.action`
// 编辑
const updateDeviceRoomURL = `${API_CONFIG.baseURL}smDeviceAction!updateDeviceRoom.action`
// 获取详情
const getDeviceRoomURL = `${API_CONFIG.baseURL}smDeviceAction!queryDeviceRoom.action`
// 蓝牙列表
const getBlueteethURL = `${API_CONFIG.baseURL}smDeviceAction!queryBlueteeth.action?bindDeviceRoom=true`
// 获取维保单位
const getCategoryCommonURL = `${API_CONFIG.baseURL}smDeviceAction!queryCategoryCommon.action?type=3`
// 获取设备列表
const getDeviceListURL = `${API_CONFIG.baseURL}smDeviceAction!list.action`
// 获取组团列表
// const getBlockNameFromCommunityURL = `${API_CONFIG.baseURL}serverRoomAction!queryBlockNameFromCommunity.action`
// 批量修改责任人
const batchUpdateChargerURL = `${API_CONFIG.baseURL}smDeviceAction!batchUpdateCharger.action`
// 批量修改维保单位
const batchUpdateMaintCompanyURL = `${API_CONFIG.baseURL}smDeviceAction!batchUpdateMaintCompany.action`
// 获取设备分类列表
const getCategoryThirdListURL = `${API_CONFIG.baseURL}smDeviceAction!categoryList.action?codeLevel=3`
// 导入
const roomImportURL = `${API_CONFIG.butlerBaseURL}device/room/import`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getCateGoryURL,
  saveDeviceRoomURL,
  updateDeviceRoomURL,
  getDeviceRoomURL,
  getBlueteethURL,
  getCategoryCommonURL,
  getDeviceListURL,
  // getBlockNameFromCommunityURL,
  batchUpdateChargerURL,
  batchUpdateMaintCompanyURL,
  getCategoryThirdListURL,
  roomImportURL
}
