<template>
  <div class="deviceHouseList-wrapper">
    <list
      ref="list"
      exportPermission="exportRoom"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新建设备房" permission="roomAdd" @click="create"></v-button>
        <v-button text="导入" permission="roomAdd" @click="goImport"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="设备房名称" v-model="searchParams.name"></v-input>
        <v-select2 label="分类" v-model="searchParams.categoryId" v-bind="categoryParams"></v-select2>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="安装位置" v-model="searchParams.location"></v-input>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
        <v-datepicker label="时间" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
        <v-input label="登记人" v-model="searchParams.userName"></v-input>
        <v-input label="关联个数" v-model="searchParams.count"></v-input>
        <v-input label="关联蓝牙" v-model="searchParams.serialNum"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="updateRoom" @click="edit(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除" permission="delRoom" @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  batchURL,
  getCateGoryURL
} from './api'
import { createAlinkVNode } from 'common/vdom'
import { communityParams, tenantParams } from 'common/select2Params'


export default {
  name: 'DeviceHouseList',
  data () {
    let _this = this
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      communityParams,
      tenantParams,
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '开启',
          value: 1
        }, {
          text: '关闭',
          value: 0
        }
      ],
      categoryParams: {
        searchUrl: getCateGoryURL,
        request: {
          text: 'value',
          value: 'id'
        },
        response: {
          text: 'value',
          value: 'id'
        }
      },
      searchParams: {
        name: '',
        categoryId: '',
        communityId: '',
        location: '',
        regionId: '',
        startDate: '',
        endDate: undefined,
        userName: '',
        count: '',
        serialNum: ''
      },
      headers: [
        {
          prop: 'name',
          label: '设备名称'
        },
        {
          prop: 'categoryName',
          label: '分类'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'location',
          label: '安装位置'
        },
        {
          prop: 'userName',
          label: '登记人'
        },
        {
          prop: 'serialNum',
          label: '关联蓝牙'
        },
        {
          prop: 'intime',
          label: '登记时间'
        },
        {
          prop: 'count',
          label: '关联设备',
          align: 'right',
          formatter: (row, prop) => {
            let opts = {
              cb: _this.goPage
            }
            return createAlinkVNode(this, row, prop, opts)
          }
        }
      ],
      postConfig: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
    }
  },

  methods: {
    updateStatus (ids, batchURL) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        ids: _ids,
        dataObject: _ids
      }
      _this.$axios.post(batchURL, this.$qs.stringify(postData), this.postConfig).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    async batch (selected) {
      let isOk = await this.$confirm('确定执行该操作？')
      isOk && this.updateStatus(selected.ids, batchURL)
    },
    create () {
      this.$router.push({
        name: 'deviceHouseForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'deviceHouseForm',
        query: {
          id: row.id
        }
      })
    },
    goPage (row) {
      this.$router.push({
        name: 'device1',
        query: {
          deviceRoomId: row.id
        }
      })
    },
    goImport () {
      this.$router.push({
        name: 'deviceHouseImport'
      })
    }
  }
}
</script>
<style scoped>
>>> .aStyle{
  color: #1b8cf2;
  cursor:pointer;
}
</style>
